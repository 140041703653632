<template>
    <div class="detail">
        <van-nav-bar
                title="房贷计算器"
                left-text="返回"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <div class="cal_top">
            <div class="cal_nav">
                <div class="cal_changenav" :class="formData.top == 'benxi' ? 'active_nav' : ''"
                     @click="navClick('benxi')">
                    等额本息
                </div>
                <div class="cal_changenav" :class="formData.top == 'benjin' ? 'active_nav' : ''"
                     @click="navClick('benjin')">
                    等额本金
                </div>
            </div>
            <van-row class="infototal">
                <van-col span="6">
                    <div class="infotli flexli">
                        <div class="name">还款总额(万)</div>
                        <div class="price" id="totalPrice">{{totalPrice}}</div>
                    </div>
                </van-col>
                <van-col span="6">
                    <div class="infotli flexli">
                        <div class="name">总利息(万)</div>
                        <div class="price" id="totalLixi">{{totalLixi}}</div>
                    </div>
                </van-col>
                <van-col span="6">
                    <div class="infotli flexli">
                        <div class="name">贷款总额(万)</div>
                        <div class="price" id="totalDknum">{{totalDknum}}</div>
                    </div>
                </van-col>
                <van-col span="6">
                    <div class="infotli flexli">
                        <div class="name">贷款期限</div>
                        <div class="price" id="totalyear">{{totalyear}}</div>
                    </div>
                </van-col>
            </van-row>
        </div>
        <van-sticky>
            <van-row class="row-table">
                <van-col span="6">期次</van-col>
                <van-col span="6">每月本金</van-col>
                <van-col span="6">每月利息</van-col>
                <van-col span="6">剩余还款</van-col>
            </van-row>
        </van-sticky>
        <div v-for="(item,index) in arr" :key="index">
            <div class="yeartitle">第{{item.year}}年</div>
            <van-row v-for="(mouth,mouthIndex) in item.mouth" :key="mouthIndex" class="row-table">
                <van-col span="6">{{mouth.monthName}}</van-col>
                <van-col span="6">{{mouth.yuebenjin}}</van-col>
                <van-col span="6">{{mouth.yuelixi}}</van-col>
                <van-col span="6">{{mouth.leftFund}}</van-col>
            </van-row>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'detail',
        data() {
            return {
                formData: {
                    top: 'benxi',
                },
                totalPrice: '0.00',
                totalLixi: '0.00',
                totalDknum: '0.00',
                totalyear: '20',
                arr: [],
            };
        },
        created() {
            this.resultBind();
        },
        methods: {
            navClick(value) {
                this.formData.top = value;
                if (value == "benxi") {
                    this.resultBind("1");
                } else if (value == "benjin") {
                    this.resultBind("2");
                }
            },
            resultBind: function (type) {
                let loantype = this.$route.query.loantype;
                if (loantype == "1") {
                    type = type || this.$route.query.type;
                    let sdnum = this.$route.query.sdnum;
                    let sdyear = this.$route.query.sdyear;
                    let sdlilv = this.$route.query.sdlilv;

                    let ressdobj = this.singleDk(type, sdnum, sdyear, sdlilv);
                    //console.log(resobj);
                    this.domOperates(type, ressdobj);

                } else if (loantype == "2") {
                    type = type || this.$route.query.type;
                    let gjjnum = this.$route.query.gjjnum;
                    let gjjyear = this.$route.query.gjjyear;
                    let gjjlilv = this.$route.query.gjjlilv;

                    let resgjjobj = this.singleDk(type, gjjnum, gjjyear, gjjlilv);
                    //console.log(resobj);
                    this.domOperates(type, resgjjobj);
                } else if (loantype == "3") {
                    type = type || this.$route.query.type;
                    let gjjnum = this.$route.query.gjjnum;
                    let gjjyear = this.$route.query.gjjyear;
                    let gjjlilv = this.$route.query.gjjlilv;
                    let sdnum = this.$route.query.sdnum;
                    let sdyear = this.$route.query.sdyear;
                    let sdlilv = this.$route.query.sdlilv;
                    let reszhobj = this.zuhe(type, sdnum, gjjnum, sdyear, gjjyear, sdlilv, gjjlilv);
                    //console.log(resobj);
                    this.domOperates(type, reszhobj);
                }
            },
            domOperates: function (type, data) {
                let totalPrice = this.formatCurrency(data.totalPrice / 10000); //万元转换
                let totalLixi = this.formatCurrency(data.totalLixi / 10000); //万元转换
                //详情页面
                let totalDknum = this.formatCurrency(data.totalDknum);
                let mouthdataArray = data.mouthdataArray;
                this.totalPrice = totalPrice
                this.totalLixi = totalLixi
                this.totalDknum = totalDknum
                this.totalyear = data.year

                let arr = [
                    {
                        year: '',
                        mouth: [
                            {
                                monthName: '',
                                yuebenjin: '',
                                yuelixi: '',
                                leftFund: '',
                            }
                        ]
                    }
                ];
                arr = [];
                let yearflag = 1;
                let _this = this;

                //详情页面循环
                mouthdataArray.forEach(function (item) {
                    let pushnum = parseInt(item.monthName);
                    let mouth = {
                        monthName: item.monthName,
                        yuebenjin: _this.formatCurrency(item.yuebenjin),
                        yuelixi: _this.formatCurrency(item.yuelixi),
                        leftFund: _this.formatCurrency(item.leftFund),
                    }
                    if (arr[yearflag - 1] == undefined) arr[yearflag - 1] = {year: yearflag, mouth: []};
                    arr[yearflag - 1].mouth.push(mouth);
                    if (pushnum == 12) {
                        yearflag++;
                    }
                });
                this.arr = arr;
            },
            zuhe: function (type, sdnum, gjjnum, sdyear, gjjyear, sdlilv, gjjlilv) {
                let _this = this,
                    year = sdyear > gjjyear ? sdyear : gjjyear;
                let mergemouthdataArray = '';
                if (type == 1) {
                    let sdObj = _this.benxi(type, sdnum, sdyear, sdlilv);
                    let gjjObj = _this.benxi(type, gjjnum, gjjyear, gjjlilv);
                    if (sdObj.mouthdataArray.length > gjjObj.mouthdataArray.length) {
                        mergemouthdataArray = sdObj.mouthdataArray.map(function (item, index) {
                            if (index < gjjObj.mouthdataArray.length) {
                                return {
                                    monthName: item.monthName,
                                    yuelixi: item.yuelixi + gjjObj.mouthdataArray[index].yuelixi,
                                    yuebenjin: item.yuebenjin + gjjObj.mouthdataArray[index].yuebenjin,
                                    leftFund: item.leftFund + gjjObj.mouthdataArray[index].leftFund
                                }
                            } else {
                                return {
                                    monthName: item.monthName,
                                    yuelixi: item.yuelixi,
                                    yuebenjin: item.yuebenjin,
                                    leftFund: item.leftFund
                                }
                            }

                        })
                    } else {
                        mergemouthdataArray = gjjObj.mouthdataArray.map(function (item, index) {
                            if (index < sdObj.mouthdataArray.length) {
                                return {
                                    monthName: item.monthName,
                                    yuelixi: item.yuelixi + sdObj.mouthdataArray[index].yuelixi,
                                    yuebenjin: item.yuebenjin + sdObj.mouthdataArray[index].yuebenjin,
                                    leftFund: item.leftFund + sdObj.mouthdataArray[index].leftFund
                                }
                            } else {
                                return {
                                    monthName: item.monthName,
                                    yuelixi: item.yuelixi,
                                    yuebenjin: item.yuebenjin,
                                    leftFund: item.leftFund
                                }
                            }
                        })
                    }
                    return {
                        yuegong: sdObj.yuegong + gjjObj.yuegong,
                        totalLixi: sdObj.totalLixi + gjjObj.totalLixi,
                        totalPrice: sdObj.totalPrice + gjjObj.totalPrice,
                        mouthdataArray: mergemouthdataArray,
                        totalDknum: parseFloat(sdObj.totalDknum) + parseFloat(gjjObj.totalDknum),
                        year: year
                    }

                } else if (type == 2) {
                    let sdObj = _this.benjin(type, sdnum, sdyear, sdlilv);
                    let gjjObj = _this.benjin(type, gjjnum, gjjyear, gjjlilv);
                    if (sdObj.mouthdataArray.length > gjjObj.mouthdataArray.length) {
                        mergemouthdataArray = sdObj.mouthdataArray.map(function (item, index) {
                            if (index < gjjObj.mouthdataArray.length) {
                                return {
                                    monthName: item.monthName,
                                    yuelixi: item.yuelixi + gjjObj.mouthdataArray[index].yuelixi,
                                    yuebenjin: item.yuebenjin + gjjObj.mouthdataArray[index].yuebenjin,
                                    leftFund: item.leftFund + gjjObj.mouthdataArray[index].leftFund
                                }
                            } else {
                                return {
                                    monthName: item.monthName,
                                    yuelixi: item.yuelixi,
                                    yuebenjin: item.yuebenjin,
                                    leftFund: item.leftFund
                                }
                            }

                        })
                    } else {
                        mergemouthdataArray = gjjObj.mouthdataArray.map(function (item, index) {
                            if (index < sdObj.mouthdataArray.length) {
                                return {
                                    monthName: item.monthName,
                                    yuelixi: item.yuelixi + sdObj.mouthdataArray[index].yuelixi,
                                    yuebenjin: item.yuebenjin + sdObj.mouthdataArray[index].yuebenjin,
                                    leftFund: item.leftFund + sdObj.mouthdataArray[index].leftFund
                                }
                            } else {
                                return {
                                    monthName: item.monthName,
                                    yuelixi: item.yuelixi,
                                    yuebenjin: item.yuebenjin,
                                    leftFund: item.leftFund
                                }
                            }
                        })
                    }
                    return {
                        yuegong: sdObj.yuegong + gjjObj.yuegong,
                        totalLixi: sdObj.totalLixi + gjjObj.totalLixi,
                        totalPrice: sdObj.totalPrice + gjjObj.totalPrice,
                        yuegongdijian: sdObj.yuegongdijian + gjjObj.yuegongdijian,
                        totalDknum: parseFloat(sdObj.totalDknum) + parseFloat(gjjObj.totalDknum),
                        year: year,
                        mouthdataArray: mergemouthdataArray
                    }
                }

            },
            singleDk: function (type, num, year, lilv) {
                let _this = this;
                // type:1等额本息 2等额本金，num 贷款金额 year贷款年限，lilv：贷款基准利率
                if (type == 1) {
                    return _this.benxi(type, num, year, lilv)
                } else if (type == 2) {
                    return _this.benjin(type, num, year, lilv)
                }
            },
            benxi: function (type, num, year, lilv) {
                //每月月供额=〔贷款本金×月利率×(1＋月利率)＾还款月数〕÷〔(1＋月利率)＾还款月数-1〕
                let mouth = parseInt(year) * 12,
                    mouthlilv = parseFloat(lilv) / 12,
                    dknum = parseFloat(num) * 10000;
                //每月月供
                let yuegong = (dknum * mouthlilv * Math.pow((1 + mouthlilv), mouth)) / (Math.pow((1 + mouthlilv), mouth) - 1);
                //总利息=还款月数×每月月供额-贷款本金
                let totalLixi = mouth * yuegong - dknum;
                //还款总额 总利息+贷款本金
                let totalPrice = totalLixi + dknum,
                    leftFund = totalLixi + dknum;

                //循环月份
                let mouthdataArray = [],
                    nowmouth = new Date().getMonth(),
                    realmonth = 0;

                for (let i = 1; i <= mouth; i++) {
                    realmonth = nowmouth + i;
                    let yearlist = Math.floor(i / 12);

                    realmonth = realmonth - 12 * yearlist;

                    if (realmonth > 12) {
                        realmonth = realmonth - 12
                    }
                    //console.log(realmonth)
                    //每月应还利息=贷款本金×月利率×〔(1+月利率)^还款月数-(1+月利率)^(还款月序号-1)〕÷〔(1+月利率)^还款月数-1〕
                    let yuelixi = dknum * mouthlilv * (Math.pow((1 + mouthlilv), mouth) - Math.pow((1 + mouthlilv), i - 1)) / (Math.pow((1 + mouthlilv), mouth) - 1);
                    //每月应还本金=贷款本金×月利率×(1+月利率)^(还款月序号-1)÷〔(1+月利率)^还款月数-1〕
                    let yuebenjin = dknum * mouthlilv * Math.pow((1 + mouthlilv), i - 1) / (Math.pow((1 + mouthlilv), mouth) - 1);
                    leftFund = leftFund - (yuelixi + yuebenjin);
                    if (leftFund < 0) {
                        leftFund = 0
                    }
                    mouthdataArray[i - 1] = {
                        monthName: realmonth + "月",
                        yuelixi: yuelixi,
                        yuebenjin: yuebenjin,
                        //剩余还款
                        leftFund: leftFund
                    }
                }
                return {
                    yuegong: yuegong,
                    totalLixi: totalLixi,
                    totalPrice: totalPrice,
                    mouthdataArray: mouthdataArray,
                    totalDknum: num,
                    year: year
                };
            },
            //等额本金计算
            benjin: function (type, num, year, lilv) {
                let mouth = parseInt(year) * 12,
                    mouthlilv = parseFloat(lilv) / 12,
                    dknum = parseFloat(num) * 10000,
                    yhbenjin = 0; //首月还款已还本金金额是0
                //每月应还本金=贷款本金÷还款月数
                let everymonthyh = dknum / mouth
                //每月月供额=(贷款本金÷还款月数)+(贷款本金-已归还本金累计额)×月利率
                let yuegong = everymonthyh + (dknum - yhbenjin) * mouthlilv;
                //每月月供递减额=每月应还本金×月利率=贷款本金÷还款月数×月利率
                let yuegongdijian = everymonthyh * mouthlilv;
                //总利息=〔(总贷款额÷还款月数+总贷款额×月利率)+总贷款额÷还款月数×(1+月利率)〕÷2×还款月数-总贷款额
                let totalLixi = ((everymonthyh + dknum * mouthlilv) + dknum / mouth * (1 + mouthlilv)) / 2 * mouth - dknum;
                //还款总额 总利息+贷款本金
                let totalPrice = totalLixi + dknum,
                    leftFund = totalLixi + dknum;

                //循环月份
                let mouthdataArray = [],
                    nowmouth = new Date().getMonth(),
                    realmonth = 0;

                for (let i = 1; i <= mouth; i++) {
                    realmonth = nowmouth + i;
                    let yearlist = Math.floor(i / 12);

                    realmonth = realmonth - 12 * yearlist;

                    if (realmonth > 12) {
                        realmonth = realmonth - 12
                    }
                    yhbenjin = everymonthyh * (i - 1);
                    let yuebenjin = everymonthyh + (dknum - yhbenjin) * mouthlilv;
                    //每月应还利息=剩余本金×月利率=(贷款本金-已归还本金累计额)×月利率
                    let yuelixi = (dknum - yhbenjin) * mouthlilv;
                    leftFund = leftFund - yuebenjin;
                    if (leftFund < 0) {
                        leftFund = 0
                    }
                    mouthdataArray[i - 1] = {
                        monthName: realmonth + "月",
                        yuelixi: yuelixi,
                        //每月本金
                        yuebenjin: everymonthyh,
                        //剩余还款
                        leftFund: leftFund
                    }
                }
                return {
                    yuegong: yuegong,
                    totalLixi: totalLixi,
                    totalPrice: totalPrice,
                    yuegongdijian: yuegongdijian,
                    mouthdataArray: mouthdataArray,
                    totalDknum: num,
                    year: year
                }

            },
            formatCurrency: function (num) { //将数值四舍五入(保留2位小数)后格式化成金额形式
                num = num.toString().replace(/\$|,/g, '');
                if (isNaN(num))
                    num = "0";
                let sign = (num == (num = Math.abs(num)));
                num = Math.floor(num * 100 + 0.50000000001);
                let cents = num % 100;
                num = Math.floor(num / 100).toString();
                if (cents < 10)
                    cents = "0" + cents;
                for (let i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
                    num = num.substring(0, num.length - (4 * i + 3)) + ',' +
                        num.substring(num.length - (4 * i + 3));
                return (((sign) ? '' : '-') + num + '.' + cents);
            },
        },
    }
</script>

<style scoped lang="less">
    .cal_top {
        background: linear-gradient(to left, #09f77b, #07c160);
        color: #fff;
        font-size: 12px;
        padding: 1px 0;

        .cal_nav {
            width: 180px;
            height: 28px;
            margin: 15px auto 0;
            display: flex;

            .cal_changenav {
                width: 50%;
                color: #07c160;
                text-align: center;
                line-height: 28px;
                border: 1px solid #fff;
                background-color: #fff;

                &:first-child {
                    border-radius: 6px 0 0 6px;
                    border-right: 0;
                }

                &:last-child {
                    border-radius: 0 6px 6px 0;
                }

                &.active_nav {
                    background-color: #07c160;
                    color: #fff;
                }
            }
        }

        .infototal {
            width: 95%;
            margin: 10px auto;
            text-align: center;
            font-size: 12px;

            .price {
                margin-top: 10px;
                font-size: 16px;
            }
        }
    }

    .yeartitle {
        background: #F5F5F5;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: #666;
        text-indent: 25px;
    }

    .row-table {
        background-color: #fff;
        text-align: center;
        font-size: 14px;
        line-height: 40px;
        border-bottom: 1px solid #f5f5f5;

    }
</style>
